import { PAID_PLANS_APP_DEF_ID } from '../components/MySubscriptions/constants';
import {
  Subscription,
  SubscriptionStatus,
  CollectionMethod,
  DurationUnit,
} from '@wix/ambassador-billing-v1-subscription/types';

export const subscriptionsBassFixture: Subscription[] = [
  {
    id: 'mock-12345',
    name: 'Beginner',
    startDate: new Date(),
    endDate: new Date(),
    status: SubscriptionStatus.ACTIVE,
    billingStatus: {
      nextBillingDate: new Date(),
      previousBillingDate: new Date(),
      remainingCycles: 3,
      currentCycle: 3,
    },
    billingSettings: {
      collectionMethod: CollectionMethod.AUTO_CHARGE,
      totalCycles: 6,
      paymentMethod: {
        id: 'creditCard',
      },
      currency: 'EUR',
      cycleDuration: {
        unit: DurationUnit.MONTH,
        count: 1,
      },
      freeTrialDuration: {
        unit: DurationUnit.MONTH,
        count: 3,
      },
    },
    origin: {
      appId: PAID_PLANS_APP_DEF_ID,
    },
    items: [
      {
        pricingModel: {
          fixedPrice: {
            itemPrice: '15',
          },
        },
      },
    ],
    policies: {
      customerCanCancel: true,
    },
  },
  {
    id: 'mock-1343142',
    name: 'Pro',
    startDate: new Date(),
    endDate: new Date(),
    status: SubscriptionStatus.ACTIVE,
    origin: {
      appId: PAID_PLANS_APP_DEF_ID,
    },
  },
  {
    id: 'mock-1343124',
    name: 'VIP',
    startDate: new Date(),
    endDate: new Date(),
    status: SubscriptionStatus.ENDED,
    origin: {
      appId: PAID_PLANS_APP_DEF_ID,
    },
  },
];
